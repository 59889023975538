import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Menu from "./menu";
import Footer from "./footer";
import Home from "../home";
import About from "../about";
import ContactUs from "../contact";
import Loader from "./loader";
import OurWines from "../ourWines";
import Credits from "./credits";
import WinesCards from "../ourWines/winesCards";
import GCComponent from "../gcTrailer";
import InfoDomaineBox from "../ourWines/infoDomaineBox";
import WineTechnicalSheet from "../ourWines/wineTechnicalSheet";
import InfoBox from "../ourWines/infoBox";
import OurDomainsList from "../ourWines/ourDomains";
import FindOurWines from "../ourWines/findOurWines";
import "react-toastify/dist/ReactToastify.css";
import "./responsive.css";
import CMSContainer from "../../cms/cmsContainer";

const App = () => {
  const loading = useSelector(state => state.cms.loading);
  const [editMode, setEditMode] = useState(false);
  const [_initGoogle, set_initGoogle] = useState(false);

  const updateCurrentUser = async (googleUser) => {
    if (googleUser.isSignedIn()) {
      let res = await fetch(
        "https://content.loopingz.com/auth/google/token",
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            token: googleUser.getAuthResponse().id_token
          }),
          headers: {
            "content-type": "application/json"
          }
        });
      if (res.status < 300) {
        setEditMode(!editMode)
      } else {
        toast.error("User does not have permissions", {
          autoClose: 5000
        });
      }
    }
  };

  async function initGoogle() {
    if (_initGoogle) {
      return;
    }
    set_initGoogle(true);
    return new Promise((resolve, reject) => {
      window.gapi.load("auth2", () => {
        window.gapi.auth2
          .init({
            scope: "profile email",
            client_id:
              "818880072424-762ejldk9iqgq1tuolu22lvpqc4mq15v.apps.googleusercontent.com"
          })
          .then(() => {
            window.gapi.auth2
              .getAuthInstance()
              .currentUser.listen(updateCurrentUser);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }

  const toggleEditable = async (e) => {
    if (e.shiftKey) {
      await initGoogle();
      window.gapi.auth2.getAuthInstance().signIn();
    }
  };

  return (
    <Loader loading={loading}>
      <div>
        <CMSContainer>
          <Menu
            toggleEditable={toggleEditable}
            editMode={editMode}
            className="desktopMenu"
          />
          <ToastContainer />
          <Switch id="page-wrap">
            <Route
              exact
              path="/"
              render={() => <Home editMode={editMode} />}
            />
            <Route
              exact
              path="/about-us"
              render={() => <About editMode={editMode} />}
            />
            <Route
              exact
              path="/our-wines/:region/:domain"
              render={() => <InfoDomaineBox editMode={editMode} />}
            />
            <Route
              exact
              path="/our-wines/:region/:domain/:wine"
              render={() => (
                <WineTechnicalSheet editMode={editMode} />
              )}
            />
            <Route
              exact
              path="/our-wines/:region"
              render={() => <InfoBox editMode={editMode} />}
            />
            <Route
              exact
              path="/our-wines"
              render={() => <OurWines editMode={editMode} />}
            />
            <Route
              exact
              path="/contact"
              render={() => <ContactUs editMode={editMode} />}
            />
            <Route
              exact
              path="/grand-cru"
              render={() => <GCComponent editMode={editMode} />}
            />
            <Route
              exact
              path="/find-our-wines-in-california"
              render={() => <FindOurWines editMode={editMode} />}
            />
            <Route
              exact
              path="/credits"
              render={() => <Credits editMode={editMode} />}
            />
            <Route exact path="/all-our-wines" component={WinesCards} />
            <Route exact path="/our-domains" render={(props) => <OurDomainsList editMode={editMode} {...props} />} />
            <Redirect to='/' />
          </Switch>
          <Footer />
        </CMSContainer>
      </div>
    </Loader>
  );
}

export default App;
