import React, { Component } from "react";
import "./wineTechnicalSheet.css";
import { withRouter } from "react-router";
import Breadcrumb from "../app/breadcrumb";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { CMSController } from "../../cms/cmsController";
import purpleLogo from "../../ressources/purpleLogo.svg";
import signature from "../../ressources/signature.svg";
import mobileSignature from "../../ressources/mobileSignature.svg";

class WineTechnicalSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRegion: props.match.params.region,
      selectedDomaine: props.match.params.domain,
      selectedWine: props.match.params.wine
    };
  }
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  componentWillReceiveProps(nextProps) {
    this.setState(prevState => ({
      selectedRegion: nextProps.match.params.region,
      selectedDomaine: nextProps.match.params.domain,
      selectedWine: nextProps.match.params.wine
    }));
  }
  render() {
    let { selectedWine, selectedDomaine } = this.state;
    const winesSpec = CMSController.labels.winesSpecifications
      .filter(
        wineSpec =>
          wineSpec.Nom === selectedWine &&
          wineSpec.Domaine === selectedDomaine
      )
      .map(
        (wineSpec, i) => {
          return (
            <div key={i} className="wineTechSheet" id="wineTechSheetPrint">
              <img id="wineTechSheetHeaderLogo" src={purpleLogo} alt="logo" />

              {wineSpec.imgSrc ? (
                <img
                  className="wineTechSheetVignette"
                  alt="Wine label"
                  src={wineSpec.imgSrc}
                />
              ) : undefined}
              <div className="wineDenomination">
                <p>
                  {wineSpec.Domaine}, {wineSpec.Cepage}, {wineSpec.Nom}
                </p>
              </div>
                

              <ul className="wineTechSheetContent">
                {wineSpec.About &&
                  <li>
                    <p className="leftCell">ABOUT</p>
                    <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.About}}></p>
                  </li>
                }

                {wineSpec.Appellation &&
                  <li>
                    <p className="leftCell">APPELLATION</p>
                    <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.Appellation}}></p>
                  </li>
                }
                {wineSpec.Terroirs &&
                  <li>
                    <p className="leftCell">TERROIR</p>
                    <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.Terroirs}}></p>
                  </li>
                }
                <li>
                  <p className="leftCell">FARMING</p>
                  <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.Farming}}></p>
                </li>
                {wineSpec.GrapeVarietals &&
                  <li>
                    <p className="leftCell">GRAPE VARIETALS</p>
                    <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.GrapeVarietals}}></p>
                  </li>
                }
                {wineSpec.VineAge &&
                  <li>
                    <p className="leftCell">VINE AGE</p>
                    <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.VineAge}}></p>
                  </li>
                }
                {wineSpec.AverageYield &&
                  <li>
                    <p className="leftCell">AVERAGE YIELD</p>
                    <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.AverageYield}}></p>
                  </li>
                }
                {wineSpec.WineMaking &&
                  <li>
                    <p className="leftCell">WINEMAKING</p>
                    <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.WineMaking}}></p>
                  </li>
                }
                {wineSpec.TastingNotes &&
                  <li>
                    <p className="leftCell">TASTING NOTES</p>
                    <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.TastingNotes}}></p>
                  </li>
                }
                {wineSpec.AnnualProduction &&
                  <li>
                    <p className="leftCell">ANNUAL PRODUCTION</p>
                    <p className="rightCell" dangerouslySetInnerHTML={{__html: wineSpec.AnnualProduction}}></p>
                  </li>
                }
              </ul>
              <div>
                <img id="signature" src={signature} alt="signature" />
              </div>
            </div>
          );
        }
      );
    return (
      <div>
        <Breadcrumb />
        <div className="modalWineSpec">
          <PrintProvider>
            <Print name="pri" single={true}>
              {winesSpec}
              <NoPrint>
                <div id="technicalSheetMobileFooter">
                  <h4>alinewines.com</h4>
                  <div className="technicalSheetFlexFooterBorder" />
                  <a href="mailto:aline@alinewines.com" className="href">
                    <h4>aline@alinewines.com</h4>
                  </a>

                  <div className="technicalSheetFlexFooterBorder" />
                  <a href="tel:+1(858)361-4529" className="href">
                    <h4>+1 (858) 361-4529</h4>
                  </a>
                  <img className="mobileSignature" src={mobileSignature} alt="signature" />
                </div>

              </NoPrint>
            </Print>
          </PrintProvider>
        </div>
      </div>
    );
  }
}

export default withRouter(WineTechnicalSheet);
