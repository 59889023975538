import React, { Component } from "react";
import "./index.css";
import Vimeo from "@u-wave/react-vimeo";
import { isMobile } from "react-device-detect";
import CMSLabel from "../../cms/cmsLabels";
import whiteLogo from "../../ressources/whiteLogo.svg";


class GCComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trailerWidth: this.getTrailerWidth()
    };
  }
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
    if (!isMobile) {
      window.visualViewport.addEventListener("resize", this.getWindowWidth);
    }
  }
  getTrailerWidth() {
    if (isMobile) {
      return "350px";
    } else {
      return window.visualViewport.width * 0.8;
    }
  }
  getWindowWidth(props) {
    return {
      trailerWidth: window.visualViewport.width * 0.8
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState(state => this.getWindowWidth(nextProps));
  }
  render() {
    return (
      <div id="gcComponent">
        <img
          id="mobileLogo"
          src={whiteLogo}
          alt="logo"
        />
        <CMSLabel
          divType="h1"
          label="labels.gc.gcTitle1"
          contentEditable={this.props.editMode}
          suppressContentEditableWarning
        />
        <CMSLabel
          divType="div"
          className="gcContent"
          label="labels.gc.gcContent1"
          contentEditable={this.props.editMode}
          suppressContentEditableWarning
        />
        <a rel="noopener noreferrer" href="https://www.grandcrufilm.com/screenings/2018/11/6/watch-on-demand-in-canada-amp-usa" target="_blank"><h2 className="watchIt">Watch the Film</h2></a>
        <CMSLabel
          divType="h1"
          label="labels.gc.gcTitle2"
          contentEditable={this.props.editMode}
          suppressContentEditableWarning
        />
        <CMSLabel
          divType="h1"
          label="labels.gc.gcTitle3"
          contentEditable={this.props.editMode}
          suppressContentEditableWarning
        />
        <Vimeo
          id="gcTrailer"
          video="239539700"
          autoplay={false}
          width={this.state.trailerWidth}
        />
        <img
          id="grandCruBanner"
          src="/images/grandCru/Title_1080_Final.png"
          alt="Grand Cru Banner"
          style={{width: '75%'}}
        />
        <CMSLabel
          divType="h1"
          label="labels.gc.gcTitle4"
          contentEditable={this.props.editMode}
          suppressContentEditableWarning
        />
        <img
          id="grandCruAwards"
          src="/images/grandCru/GC_Awards.png"
          alt="Grand Cru Awards"
          style={{width: '75%'}}
        />
        <CMSLabel
          divType="h1"
          label="labels.gc.gcCreditsTitle"
          contentEditable={this.props.editMode}
          suppressContentEditableWarning
        />
        <div className="gcCredits">
          <div>
            <CMSLabel
              divType="h2"
              label="labels.gc.gcCredits1"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits2"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
          </div>
          <div>
            <CMSLabel
              divType="h2"
              label="labels.gc.gcCredits3"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits4"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits5"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
          </div>
          <div>
            <CMSLabel
              divType="h2"
              label="labels.gc.gcCredits6"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits7"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
          </div>
          <div>
            <CMSLabel
              divType="h2"
              label="labels.gc.gcCredits8"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits9"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
          </div>
          <div>
            <CMSLabel
              divType="h2"
              label="labels.gc.gcCredits10"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits11"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
          </div>
          <div>
            <CMSLabel
              divType="h2"
              label="labels.gc.gcCredits12"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits13"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
          </div>
          <div>
            <CMSLabel
              divType="h2"
              label="labels.gc.gcCredits14"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits15"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
          </div>
          <div>
            <CMSLabel
              divType="h2"
              label="labels.gc.gcCredits16"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits17"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits18"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.gc.gcCredits19"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
          </div>
        </div>
        <CMSLabel
          divType="h1"
          label="labels.gc.gcCredits20"
          contentEditable={this.props.editMode}
          suppressContentEditableWarning
        />
        <div className="gcPartnersLogos">
          <img
            id="grandCruPartners"
            src="/images/grandCru/logo_catbird.png"
            alt="Grand Cru Partners"
          />
          <img
            id="grandCruPartners"
            src="/images/grandCru/logo_cmf.png"
            alt="Grand Cru Partners"
          />
          <img
            id="grandCruPartners"
            src="/images/grandCru/logo_cndtx.png"
            alt="Grand Cru Partners"
          />
          <img
            id="grandCruPartners"
            src="/images/grandCru/logo_documentary.png"
            alt="Grand Cru Partners"
          />
          <img
            id="grandCruPartners"
            src="/images/grandCru/logo_qctx.png"
            alt="Grand Cru Partners"
          />
          <img
            id="grandCruPartners"
            src="/images/grandCru/logo_roger.png"
            alt="Grand Cru Partners"
          />
        </div>
      </div>
    );
  }
}

export default GCComponent;
