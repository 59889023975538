import React from "react";
import bgVideo from "../../ressources/Short_HD_background1.mp4";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  backgroundVideo: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    minWidth: '100%',
    minHeight: '100vh',
    width: 'auto',
    height: 'auto',
    zIndex: '-100',
    transform: 'translate(-50%, -50%)',
    webkitTransform: 'translate(-50%, -50%)',
    mozTransform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    oTransform: 'translate(-50%, -50%)'
  }
})

const VideoBackground = (props) => {
  const classes = useStyles();
  /*
  if (isIOS) {
    return (
      <img
        className={classes.backgroundVideo}
        src={this.state.src}
        autoPlay={false}
        loop
        alt="mock video"
      />
    );
  }
  */
  return (
    <video
      className={classes.backgroundVideo}
      src={bgVideo}
      autoPlay={true}
      loop
    />
  );
}

export default VideoBackground;
