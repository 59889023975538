import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import chevronRight from "../../ressources/svgIcons/chevron-right.svg";
import "./breadcrumb.css";

class Breadcrump extends Component {
  constructor(props) {
    super(props);
    this.state = this.getStateColors(props);
  }
  getStateColors(props) {
    if (
      props.location.startsWith("/our-wines") ||
      props.location === "/contact" ||
      props.location === "/grand-cru"
    ) {
      return {
        textColor: "#BC204B"
      };
    } else {
      return {
        textColor: "white"
      };
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState(state => this.getStateColors(nextProps));
  }
  render() {
    var textColor = {
      color: this.state.textColor
    };
    let steps = this.props.location.split("/");
    let route = "";
    const style = { stroke: { textColor } };
    const breadcrumb = steps.map((item, i) => {
      if (!item.length) return [];
      route += "/" + item;
      if (i === 1) {
        return (
          <Link className="mapLink" key={i} to={route}>
            <p className="breadCrumbLink" style={textColor}>
              {item}
            </p>
          </Link>
        );
      } else {
        return (
          <Link className="mapLink" key={i} to={route}>
            <img
              className="chevronRight"
              alt="navigation chevron right"
              style={style}
              src={chevronRight}
            />
            <p className="breadCrumbLink" style={textColor}>
              {item}
            </p>
          </Link>
        );
      }
    });
    return <div className="breadcrumb">{breadcrumb}</div>;
  }
}
const mapStateToProps = state => {
  return {
    location: state.router.location.pathname
  };
};

export default connect(mapStateToProps)(Breadcrump);
