import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { routerMiddleware } from 'connected-react-router'
//import createRootReducer from './modules/rootReducer'
import thunk from 'redux-thunk';
import { Controller } from "redux-lz-controller";
//import * as ReduxThunk from "redux-thunk";
import { connectRouter } from 'connected-react-router'

export const history = createBrowserHistory();

const composes = [
  applyMiddleware(
    routerMiddleware(history)
  ),
  applyMiddleware(thunk)
];

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  composes.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}
// && window.__REDUX_DEVTOOLS_EXTENSION__();
export default function configureStore(preloadedState) {
  const store = createStore(
    combineReducers({cms: Controller.get("cms").getReducer(),router: connectRouter(history)}), // root reducer with router state
    preloadedState,
    compose(...composes)
  )
  Controller.setStore(store);
  return store
}