import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CMSController } from "../../cms/cmsController";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/LibraryAdd";
import DeleteIcon from "@material-ui/icons/Delete";
import AddFormComponent from "./formAddComponent";
import ModifyFormComponent from "./formModifyComponent";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import purpleLogo from "../../ressources/purpleLogo.svg";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { TextField } from "@material-ui/core";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const G_API_KEY = "AIzaSyAIZ1sWJ8ZeEE4oI0xpxx1n9WSvBqRa5LI";
const styles = theme => ({
  root: {
    paddingTop: "100px",
    minHeight: "90vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap"
  },
  mainTitle: {
    // eslint-disable-next-line
    ["@media (max-width: 1065px)"]: {
      fontSize: "2em",
      textAlign: "center"
    }
  },
  categoriesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
    flexWrap: "wrap",
    marginTop: 20,
    // eslint-disable-next-line
    ["@media (max-width: 1065px)"]: {
      flexDirection: "column"
    }
  },
  categories: {
    width: 350,
    "& h2": {
      color: "#BC204B",
      textAlign: "center",
      width: 250,
      margin: "auto"
    },
    // eslint-disable-next-line
    ["@media (max-width: 1065px)"]: {
      padding: 20
    }
  },
  borders: {
    borderBottom: "solid #BC204B",
    borderBottomWidth: "1px",
    margin: "20px 80px 0 80px"
  },
  modal: {
    overflow: "scroll",
    padding: "50px"
  },
  placeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    paddingTop: 5,
    paddingBottom: 5,
    "& a": {
      textDecoration: "none",
      color: "black"
    }
  }
});

 
function RetailersMap() {
  // 
  const setMap = React.useState(null)[1];
 
  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
    navigator.geolocation.getCurrentPosition((position) => {
      map.setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
      map.setZoom(8);
    });

    const mappers = {
      independantWineShops: "store",
      neighborhoodRestaurants: "restaurant",
      michelinStarEstablishments: "star"
    }

    Object.keys(mappers).forEach(category => {
      CMSController.labels[category].forEach(info => {
        const infowindow = new window.google.maps.InfoWindow({
          content: `<h3>${info.name}</h3><div style="margin-bottom: 10px">${info.address}</div><div align="center"><a href="${info.website}">${info.website}</a></div>`,
        });
      
        const marker = new window.google.maps.Marker({
          map,
          position: info.location,
          icon: `/images/maps/pinpoint-${mappers[category]}.png`
        })

        marker.addListener("click", () => {
          infowindow.open(map, marker);
        });
      });
    });

    setMap(map)
  }, [])
 
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
 
  return (
    <LoadScript
      googleMapsApiKey={G_API_KEY}
      // "drawing" | "geometry" | "localContext" | "places" | "visualization"
      libraries={["places","visualization", "geometry", "drawing"]}
    >
      <div>
        <TextField />
        <GooglePlacesAutocomplete />
        <GoogleMap
          mapContainerClassName="gmapCustom"
          mapContainerStyle={{width: "100vw", height: 400}}
          options={{mapTypeControl: false, streetViewControl: true}}
          center={{lat: 36.2618138, lng: -120.8280841}}
          zoom={5}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          { /* Child components, such as markers, info windows, etc. */ }
        </GoogleMap>
      </div>
    </LoadScript>
  )
}

class FindOurWines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modify: false,
      formData: {},
      idxToModify: -1,
      type: ""
    };
  }
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  addPlace = type => {
    this.setState({ open: true, formData: {}, type });
  };
  modifyPlace = (idx, type) => {
    let target = CMSController.labels[type][idx];
    let formData = {
      name: target.name,
      website: target.website
    };
    this.setState({
      modify: true,
      formData: formData,
      idxToModify: idx,
      type: type
    });
  };
  submitNewPlace = (evt, idx) => {
    let newPlace = evt.formData;
    /*
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({'address': "910 Timberwood, CA92620 Irvine"}, (results, status) => {
      if (status === "OK") {
        new window.google.maps.Marker({
          map,
          position: results[0].geometry.location,
          icon: "/images/maps/pinpoint-restaurant.png"
        })
      }
    });
    */
    CMSController.addCollectionEntry(this.state.type, newPlace);
    this.closeModal();
  };

  submitPlaceModification = (evt, idx) => {
    let modifiedPlace = evt.formData;
    CMSController.modifyCollectionEntry(this.state.type, idx, modifiedPlace);
    this.closeModal();
  };

  deletePlace = (idx, type) => {
    CMSController.deleteCollectionEntry(type, idx);
    this.forceUpdate();
  };
  closeModal = () => {
    this.setState({ open: false, modify: false });
  };
  whereToFindList(type, classes) {
    const list = (CMSController.labels[type] || []).map((item, i) => {
      return (
        <div key={i} className={classes.placeContainer}>
          <a href={item.website} target="_blank" rel="noopener noreferrer">
            {item.name}
          </a>
          {this.props.editMode &&
            <div>
              <EditIcon
                onClick={() => this.modifyPlace(i, type)}
                fontSize="large"
              />
              <DeleteIcon
                onClick={() => this.deletePlace(i, type)}
                fontSize="large"
              />
            </div>
          }
        </div>
      );
    });
    return list;
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <img id="mobileLogo" src={purpleLogo} alt="logo" />
        <h1 className={classes.mainTitle}>
          Where You Can Find Our Wines in California
        </h1>
        <div style={{display: "flex", justifyContent: "center", width: "100%", paddingBottom: 30}}>
          <RetailersMap />
        </div>
        <div className={classes.categoriesContainer}>
          <div className={classes.categories}>
            {this.props.editMode &&
              <AddIcon
                fontSize="large"
                onClick={() => this.addPlace("onlineStores")}
              />
            }
            <h2>Online Shops / Wine Clubs</h2>
            <div className={classes.borders} />
            {this.whereToFindList("onlineStores", classes)}
            {this.props.editMode &&
              <AddIcon
                fontSize="large"
                onClick={() => this.addPlace("independantWineShops")}
              />
            }
            <h2>Independent Wine Shops</h2>
            <div className={classes.borders} />
            {this.whereToFindList("independantWineShops", classes)}
          </div>
          <div className={classes.categories}>
            {this.props.editMode &&
              <AddIcon
                fontSize="large"
                onClick={() => this.addPlace("neighborhoodRestaurants")}
              />
            }
            <h2>Neighborhood Restaurants</h2>
            <div className={classes.borders} />
            {this.whereToFindList("neighborhoodRestaurants", classes)}
          </div>
          <div className={classes.categories}>
            {this.props.editMode &&
              <AddIcon
                fontSize="large"
                onClick={() => this.addPlace("michelinStarEstablishments")}
              />
            }
            <h2>Michelin Star Establishments</h2>
            <div className={classes.borders} />
            {this.whereToFindList("michelinStarEstablishments", classes)}
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <Paper className={classes.root}>
            <AddFormComponent
              formTitle="Adding a new place"
              formSchema="addingPlace"
              onSubmit={evt => this.submitNewPlace(evt)}
            />
          </Paper>
        </Modal>
        <Modal
          open={this.state.modify}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <Paper className={classes.root}>
            <ModifyFormComponent
              formTitle="Modify Place"
              formSchema="modifyPlace"
              defaultValue={this.state.formData}
              onSubmit={evt =>
                this.submitPlaceModification(evt, this.state.idxToModify)
              }
            />
          </Paper>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(FindOurWines);
