import * as jp from "jsonpath";
import * as md5 from "md5";
import { base64StringToBlob } from "blob-util";
import { Controller } from "redux-lz-controller";

export class CMSController extends Controller {
  static labels = {};
  static host = "";

  constructor() {
    super("cms", {editMode: false, loading: true})
  }

  init() {
    super.init();
    this.load();
  }

  static initHost() {
    if (window.location.host === "localhost:3000") {
      CMSController.host = "aliane-dev";
    }
    else if (window.location.host === "aliane.demo.loopingz.com") {
      CMSController.host = "aliane-demo";
    }
    else if (window.location.host === "alinewines.com" || window.location.host.endsWith("alinewines.com")) {
      CMSController.host = "aliane";
    }
  }

  static async saveImage(callback, file) {
    let ext = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();
    let binary;
    let hash = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function (event) {
        binary = new Uint8Array(event.target.result);
        resolve(md5(binary));
      };
      reader.readAsArrayBuffer(file);
    }).catch(err => {
      console.log("Error occured", err);
      return file.name;
    });
    await CMSController.save(callback, binary, `${hash}${ext}`, true);
    return `https://static.loopingz.com/${CMSController.host}/${hash}${ext}`;
  }

  static async saveBase64Image(callback, file) {
    let imgRef = file.split(";");
    let ext = "." + imgRef[0].substring(imgRef[0].lastIndexOf("/") + 1);
    let contentType = imgRef[0].split(":");
    let b64content = imgRef[2].substring(imgRef[2].indexOf(",") + 1);
    let blob = await base64StringToBlob(b64content, contentType[1]);
    let hash = await md5(b64content);
    await CMSController.save(callback, blob, `${hash}${ext}`, true);
    return `https://static.loopingz.com/${CMSController.host}/${hash}${ext}`;
  }

  static async saveBase64ImagesSet(imgSetAsArray, callback) {
    let promises = imgSetAsArray.map(async file => {
      let result = await this.saveBase64Image(() => { }, file);
      return result;
    });
    const arraySet = await Promise.all(promises);
    if (callback) {
      callback();
    }
    return arraySet;
  }

  static async save(callback, content, name, unique = false) {
    content = content || JSON.stringify(CMSController.labels, undefined, 2);
    name = name || "labels.json";
    console.log("Should save", CMSController.labels);
    let res = await fetch("https://content.loopingz.com/upload", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ Key: name, UniqueKey: unique }),
      headers: {
        "Content-Type": "binary/octet-stream"
      }
    });
    if (res.status >= 300) {
      return callback(res, null);
    }
    let info = await res.json();
    if (info.Status === "UPLOADED") {
      return callback(null, "OK");
    }
    console.log("Should post to", info.Url);
    res = await fetch(info.Url, {
      method: "PUT",
      body: content,
      headers: {
        "Content-Type": "binary/octet-stream"
      }
    });
    if (res.status >= 300) {
      return callback(res, null);
    }
    callback(null, "OK");
  }

  async load(callback) {
    this.asyncAction("LOAD_LABELS", async () => {
      CMSController.initHost();
      let url = `https://static.loopingz.com/${CMSController.host}/labels.json`;
      let res = await fetch(url);
      let labels = await res.json();
      CMSController.labels = labels;
      if (callback) {
        callback();
      }
      let collections = {};
      for (let i in labels) {
        if (i === 'labels') continue;
        collections[i] = labels[i];
      }
      return {labels: labels.labels, loading: false, collections};
    })
    
  }

  static getLabel(id) {
    return jp.value(CMSController.labels, `$.${id}`);
  }

  static setLabel(id, value) {
    return jp.value(CMSController.labels, `$.${id}`, value);
  }

  static addCollectionEntry(collection, content) {
    CMSController.labels[collection] = CMSController.labels[collection] || [];
    CMSController.labels[collection] = [
      ...CMSController.labels[collection],
      content
    ];
  }

  static deleteCollectionEntry(collection, itemToDelete) {
    let collectionEntries = CMSController.labels[collection];
    let index = itemToDelete;
    if (itemToDelete instanceof Object) {
      index = collectionEntries.indexOf(itemToDelete);
      if (index < 0) {
        console.error("Cannot modify item", collection, itemToDelete, typeof itemToDelete);
        return;
      }
    }
    collectionEntries.splice(index, 1);
    CMSController.labels[collection] = collectionEntries;
  }

  static modifyCollectionEntry(collection, itemToModify, newContent) {
    let collectionEntries = CMSController.labels[collection];
    let index = itemToModify;
    if (itemToModify instanceof Object) {
      index = collectionEntries.indexOf(itemToModify);
      if (index < 0) {
        console.error("Cannot modify item", collection, itemToModify, newContent, typeof itemToModify);
        return;
      }
    }
    collectionEntries.splice(index, 1, newContent);
    CMSController.labels[collection] = collectionEntries;
  }
}

new CMSController({});