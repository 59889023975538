import React from "react";
import { CircularProgress, Typography } from "@material-ui/core";

const LoadingPanel = ({ loaded, children, label, style = {} }) => {
    let displayLabel = label || "Loading";
    return (
        !loaded ?
            <div style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                minHeight: "300px",
                width: "300px",
                flexDirection: "column"
            }}>
                <CircularProgress size={32} />
                <Typography style={{ marginTop: "20px", color: "#999" }}>{displayLabel}</Typography>
            </div>
            :
            <div style={{...style}}>{children}</div>
    )

}

export default LoadingPanel;