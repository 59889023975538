import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from '@material-ui/core/styles';
import LoadingPanel from "../app/LoadingPanel";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    pic: {
        width: 'auto',
        padding: 5
    },
    modalPic: {
        position: 'fixed',
        zIndex: 10000,
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: 'rgba(0,0,0,0.4)'
    },
    modalContent: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        border: '1px solid #888',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    img: {
        height: '100vh',
        width: 'auto'
    },
    close: {
        position: 'fixed',
        top: 10,
        right: 10,
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold'
    },
    leftArrow: {
        position: 'fixed',
        top: '50vh',
        left: 20,
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold'
    },
    rightArrow: {
        position: 'fixed',
        top: '50vh',
        right: 20,
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold'
    }
});

const Wallpaper = ({ gallery = [], height = 250 }) => {
    const classes = useStyles();
    const [selectedPic, setSelectedPic] = useState(-1);
    const picClicked = (i) => {
        setSelectedPic(i)
    }
    const closeModal = () => {
        setSelectedPic(-1)
    }
    const navLeft = () => {
        setSelectedPic(selectedPic => Math.max(selectedPic - 1, 0));
    }
    const navRight = () => {
        setSelectedPic(selectedPic => Math.min(selectedPic + 1, gallery.length - 1));
    }
    const handleValue = useCallback(
        (event) => {
            if (event.code === 'Escape') {
                closeModal();
            }
            if (event.code === 'ArrowRight') {
                navRight();
            }
            if (event.code === 'ArrowLeft') {
                navLeft();
            }
        },
        []
    );
    useEffect(
        () => {
            window.addEventListener('keydown', handleValue);
        }, []
    );

    return (
        <div className={classes.container}>
            <Gallery gallery={gallery} picClicked={picClicked} height={height} />
            {selectedPic !== -1 &&
                <div className={classes.modalPic}>
                    <div className={classes.modalContent}>
                        {selectedPic !== 0 &&
                            <p className={classes.leftArrow} onClick={navLeft}>&lt;</p>
                        }
                        {selectedPic !== gallery.length - 1 &&
                            <p className={classes.rightArrow} onClick={navRight}>&gt;</p>
                        }
                        <p className={classes.close} onClick={closeModal}>&times;</p>
                        <img className={classes.img} src={gallery[selectedPic].high || gallery[selectedPic].original} alt="" />
                    </div>
                </div>
            }
        </div>
    )
}

export default Wallpaper;

const Gallery = ({ picClicked, gallery, height }) => {
    
    return (
        gallery.map((pic, i) => {
            const src = [pic.original];
            if (pic.small) {
                src.unshift(pic.small);
            }
            return (<SingleImage
                key={i}
                src={src}
                picClicked={picClicked}
                height={height}
                index={i}
            />);

        }
        )
    )
}

const SingleImage = ({ src, picClicked, index, height }) => {
    const classes = useStyles();
    const [imgLoadingStatus, setImgLoadingStatus] = useState(false);
    const [srcIndex, setSrcIndex] = useState(0);
    const srcs = Array.isArray(src) ? src : [src];
    return (
        <div>
            <img
                src={srcs[srcIndex]}
                onLoad={() => setImgLoadingStatus(true)}
                onError={() => {
                    if (srcs.length > srcIndex + 1) {
                        setSrcIndex(srcIndex+1);
                    }
                }}
                alt=""
                hidden={true}
            />
            <LoadingPanel loaded={imgLoadingStatus} style={{ display: "flex", justifyContent: "center" }}>
                <img
                    className={classes.pic}
                    src={srcs[srcIndex]}
                    style={{ height: height }}
                    onClick={() => picClicked(index)}
                    alt=""
                />
            </LoadingPanel>
        </div>
    )
}