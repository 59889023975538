import React from "react";
import { CMSController } from "./cmsController";
import ReactHtmlParser from 'react-html-parser';

export default function CMSQuote({ id, title, content }) {
  const innerTitle = CMSController.getLabel(id + ".title") || title;
  const innerContent = CMSController.getLabel(id + ".content") || content;
  return (<div className="cmsQuote">
    <div id="sloganMoto">
      <div id="flexMoto">
        <div id="mainSlogan">{ReactHtmlParser(innerTitle)}</div>
        <p className="mainSloganContent">{ReactHtmlParser(innerContent)}</p>
      </div>
    </div>
  </div>)
}