import React from "react";
import CMSLabel from "../../cms/cmsLabels";
import alineBio from "../../ressources/alineBio.jpg";
import "./index.css";
import { makeStyles } from '@material-ui/core/styles';
import Wallpaper from "../ourWines/wallpaper";
import { WineMenu } from "../app/winemenu";
import purpleLogo from "../../ressources/purpleLogo.svg";


const useStyles = makeStyles({
  alineBioBackground: {
    position: 'fixed',
    top: 0,
    left: 'calc((100vw - 900px) / 2)',
    height: '100%',
    zIndex: '-100',
    maxWidth: '900px',
    '@media (max-width:746px)': {
      top: 243,
      left: 0,
      height: 'auto',
      width: '100%'
    }
  },
  sloganAbout: {
    minHeight: '100vh',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.5s ease -in -out',
    webkitTransition: 'all 0.5s ease -in -out',
    mozTransition: 'all 0.5s ease -in -out',
    msTransition: 'all 0.5s ease -in -out',
    oTransition: 'all 0.5s ease -in -out',
    padding: '100px 20px 100px 20px'
  },
  encart: {
    border: "1px solid #bc204b",
    backgroundColor: "#ffffff66",
    padding: 20,
    marginTop: "30vw",
    '@media (max-width:1182px)': {
      marginTop: "20vw"
    },
    '@media (max-width:850px)': {
      marginTop: "15vw"
    },
    '@media (max-width:746px)': {
      marginTop: "10vw"
    }
  }
});

const About = ({ editMode }) => {
  const classes = useStyles();
  return (
    <div id="aboutSection">
      <div id="bio">
        <img id="alineBioBackground" className={classes.alineBioBackground} src={alineBio} alt="wine cave" />
        <img
          id="mobileLogo"
          src={purpleLogo}
          alt="logo"
        />
        <div id="sloganAbout" className={classes.sloganAbout}>
          <div className={classes.encart}>
            <CMSLabel
              divType="h1"
              label="labels.about.sloganHeader1"
              contentEditable={editMode}
              suppressContentEditableWarning
            />
            <CMSLabel
              divType="h1"
              label="labels.about.sloganHeader2"
              contentEditable={editMode}
              suppressContentEditableWarning
            />
          </div>
        </div>

        <div id="bioTextContainerNext" className="contentPanel">
          <div style={{ maxWidth: "1000px" }}>
            <div id="bioPhotoSection">
              <CMSLabel
                divType="h1"
                label="labels.about.bioTitle"
                contentEditable={editMode}
                suppressContentEditableWarning
                defaultLabel="Aline Thiébaut"
              />
            </div>
            <CMSLabel
              label="labels.about.bioSection"
              contentEditable={editMode}
              suppressContentEditableWarning
              defaultLabel="I was born and raised in a small town in the Chablis region of Northern Burgundy. School vacations were spent on the farm and barrel making “atelier” of my grand-parents, in a Burgundian village on the edge of the Jura foothills. They were the last generation of a long line of artisan barrel makers, craftsmen, local small merchants and farmers, deeply rooted in their native land. As true Burgundians, they were religiously devoted to cultivating their small plots, understanding their terroirs, listening to the cycles of Nature, and honing their craft of growing, raising and making all things."
            />
          </div>
        </div>
        <div id="aboutAliane">
          <div style={{ maxWidth: "1000px" }}>
            <div className="contentPanel">
              <CMSLabel
                divType="h1"
                label="labels.about.companyName"
                contentEditable={editMode}
                suppressContentEditableWarning
                defaultLabel="Aline Wines"
              />
              <Wallpaper gallery={[{ original: "/images/about/chalky.jpg" }, { original: "/images/about/neige.jpg" }]} height="500px" />
              <CMSLabel
                label="labels.about.aboutAliane"
                contentEditable={editMode}
                suppressContentEditableWarning
                defaultLabel="During this journey, I discovered that some wines have the power to give pleasure and bring you fully alive to your senses. They create emotional experiences, and take you deep into the wisdom of their ancient soils. They also bring people joyously together, and if you listen, there are endless stories told, and connections being made"
              />
            </div>
            <WineMenu />
            <div>
              <img
                src="/images/about/community_1.jpg"
                alt="Spring in vineyard"
                style={{ width: "100%" }} />
            </div>
            <div className="contentPanel">
              <CMSLabel
                divType="h1"
                label="labels.about.communityTitle"
                contentEditable={editMode}
                suppressContentEditableWarning
                defaultLabel="Community of Aline Wines"
              />
              <CMSLabel
                label="labels.about.communityText"
                contentEditable={editMode}
                suppressContentEditableWarning
                defaultLabel="The make up of Aline Wines community was assembled partnership by partnership, and as always, with the Burgundian spirit at heart."
              />
            </div>
            <Wallpaper height="270px" gallery={['19', '14'].map(p => ({ original: `/images/home/wallpaper/${p}.jpg`, high: `/images/home/wallpaper/${p}_high.jpg`, small: `/images/home/wallpaper/${p}_sm.jpg` }))} />
            <div className="contentPanel">
              <CMSLabel
                divType="h1"
                label="labels.about.alianeTitle"
                contentEditable={editMode}
                suppressContentEditableWarning
                defaultLabel="So who is Aliane"
              />
              <CMSLabel
                label="labels.about.alianeText"
                contentEditable={editMode}
                suppressContentEditableWarning
                defaultLabel="This is my aliane text."
              />
            </div>
          </div>
          <WineMenu />
        </div>
      </div>
    </div>
  );
}

export default About;
