import React, { Component } from "react";
import purpleLogo from "../../ressources/purpleLogo.svg";

class Loader extends Component {
  render() {
    const { loading } = this.props;
    if (loading) {
      return (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            minHeight: "100vh",
            width: "100%",
            flexDirection: "column"
          }}
        >
          <img
            style={{
              width: "300px",
              height: "auto"
            }}
            src={purpleLogo}
            alt="logo"
          />
          <h1>Loading...</h1>
        </div>
      );
    }
    return <div>{this.props.children}</div>;
  }
}

export default Loader;
