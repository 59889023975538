import React, { Component } from "react";
import Form from "react-jsonschema-form";
import "bootstrap/dist/css/bootstrap.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

const ckWidget = (props) => {
  return (
    <CKEditor
      editor={InlineEditor}
      data={props.value}
      onChange={ ( event, editor ) => {
          const data = editor.getData();
          props.onChange(data)
      } } />
  );
};

export const uiSchema = {
  DomaineDescription: {
    "ui:widget": ckWidget
  },
  "About": {
    "ui:widget": ckWidget
  },
  "Appellation": {
    "ui:widget": ckWidget
  },
  "Terroirs": {
    "ui:widget": ckWidget
  },
  "Farming": {
    "ui:widget": ckWidget
  },
  "GrapeVarietals": {
    "ui:widget": ckWidget
  },
  "VineAge": {
    "ui:widget": ckWidget
  },
  "AverageYield": {
    "ui:widget": ckWidget
  },
  "WineMaking": {
    "ui:widget": ckWidget
  },
  "TastingNotes": {
    "ui:widget": ckWidget
  }
};

class ModifyFormComponent extends Component {
  render() {
    let { formTitle, onSubmit, formSchema, defaultValue } = this.props;
    const schema = {
      modifyDomain: {
        title: formTitle,
        description: "Please fill in the form to modify selected domain in the database",
        type: "object",
        required: ["DomaineName", "DomainePlace", "DomaineDescription"],
        properties: {
          DomaineName: {
            type: "string",
            title: "Domaine Name"
          },
          DomainePlace: {
            type: "string",
            title: "Domaine Place"
          },
          DomaineDescription: {
            type: "string",
            title: "Domaine Description"
          },
          imgSrc: {
            type: "string",
            format: "data-url",
            title: "Image principale"
          },
          carouselImgs: {
            type: "array",
            title: "Domaines images",
            items: {
              type: "string",
              format: "data-url"
            }
          }
        }
      },
      modifyProduct: {
        title: formTitle,
        description: "Please fill in the form to modify selected Product in the database",
        type: "object",
        required: [
          "Nom",
          "Appellation",
          "Terroirs",
          "Farming",
          "GrapeVarietals",
          "VineAge",
          "AverageYield",
          "WineMaking",
          "TastingNotes",
          "AnnualProduction"
        ],
        properties: {
          Nom: {
            type: "string",
            title: "Nom"
          },
          About: {
            type: "string",
            title: "About"
          },
          Appellation: {
            type: "string",
            title: "Appellation"
          },
          Terroirs: {
            type: "string",
            title: "Terroirs"
          },
          Farming: {
            type: "string",
            title: "Farming"
          },
          GrapeVarietals: {
            type: "string",
            title: "Grape Varietals"
          },
          VineAge: {
            type: "string",
            title: "Vine Age"
          },
          AverageYield: {
            type: "string",
            title: "Average Yield"
          },
          WineMaking: {
            type: "string",
            title: "WineMaking"
          },
          TastingNotes: {
            type: "string",
            title: "Tasting Notes"
          },
          AnnualProduction: {
            type: "string",
            title: "Annual Production"
          },
          imgSrc: {
            type: "string",
            format: "data-url",
            title: "Étiquette"
          }
        }
      },
      modifyContact: {
        title: formTitle,
        description: "Please fill in the form to modify selected Contact in the database",
        type: "object",
        required: ["name", "title", "phoneNumber", "email", "location", "area"],
        properties: {
          name: {
            type: "string",
            title: "Contact Name"
          },
          title: {
            type: "string",
            title: "Contact Title"
          },
          phoneNumber: {
            type: "string",
            title: "Phone Number"
          },
          email: {
            type: "string",
            title: "Contact Email"
          },
          location: {
            type: "string",
            title: "Contact Location",
            enum: ["Northern California", "Orange County", "San Diego", "Los Angeles", "Palm Desert"]
          },
          area: {
            type: "string",
            title: "Contact Area"
          }
        }
      },
      modifyPlace: {
        title: formTitle,
        description: "Please fill in the form to modify selected Place in the database",
        type: "object",
        required: ["name", "website"],
        properties: {
          name: {
            type: "string",
            title: "Name"
          },
          website: {
            type: "string",
            title: "Website"
          },
          address: {
            type: "string",
            title: "Address"
          },
          location: {
            type: "object",
            title: "GPS",
            properties: {
              lat: {
                type: "number",
                title: "Latitude"
              },
              lng: {
                type: "number",
                title: "Longitude"
              }
            }
          }
        }
      },
      modifyCredit: {
        title: formTitle,
        description: "Please fill in the form to modify selected credit in the database",
        type: "object",
        required: ["name", "websiteUrl"],
        properties: {
          name: {
            type: "string",
            title: "Name"
          },
          websiteUrl: {
            type: "string",
            title: "Website"
          },
          logoSrc: {
            type: "string",
            format: "data-url",
            title: "Logo"
          }
        }
      }
    };

    return <Form schema={schema[formSchema]} uiSchema={uiSchema} formData={defaultValue} onSubmit={onSubmit} />;
  }
}
export default ModifyFormComponent;
