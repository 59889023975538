import React, { Component } from "react";
import InfoBox from "./infoBox";
import Map from "./map";
import animateScrollTo from "animated-scroll-to";
import "./mapComponent.css";
import RegionsCards from "./regionsCards";
import CMSLabel from "../../cms/cmsLabels";
import { withRouter } from "react-router";
import { WineMenu } from "../app/winemenu";

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRegion: this.props.match.params.region || "",
      mainView: this.props.match.params.region === undefined
    };
  }
  scrollTo = arg => {
    animateScrollTo(document.querySelector(arg));
  };
  handleRegionClicked = selectedRegion => {
    this.props.history.push("our-wines/" + selectedRegion);
  };
  unselect = () => {
    this.setState(state => ({
      selectedRegion: null,
      mainView: true
    }));
  };

  render() {
    return (
      <div className="mapContainerFlexHorizontal">
        {this.state.mainView ? (
          <div>
            <div id="vintners">
              <WineMenu />
              <div>
                <CMSLabel
                  divType="h1"
                  label="labels.ourWines.vintnersTitle1"
                  contentEditable={this.props.editMode}
                  suppressContentEditableWarning
                />
                <CMSLabel
                  label="labels.ourWines.vintnersSection1"
                  contentEditable={this.props.editMode}
                  suppressContentEditableWarning
                />
                <CMSLabel
                  divType="h1"
                  label="labels.ourWines.ourGuarantee"
                  contentEditable={this.props.editMode}
                  defaultLabel="Our guarantees"
                  suppressContentEditableWarning
                />
                <CMSLabel
                  label="labels.ourWines.vintnersSection2"
                  contentEditable={this.props.editMode}
                  suppressContentEditableWarning
                  defaultLabel="My team of professionals care for, and protect our wines in transport, storage and delivery from our vintner’s cellars in France, to their final destination in California.

                  Provenance on all of our wines is guaranteed as a one-time direct shipping from our vintners’ domains, to our warehouse partner, VinLux, in Napa Valley.  There are no middle men and no transfer to other countries or US states involved. 
                  
                  VinLux delivers to all of our trade purveyors throughout California, offering a concierge level of service and handling, with its fleet of temperature controlled trucks and real-time tracking capability."
                />
              </div>
            </div>

            <CMSLabel
              divType="h1"
              label="labels.ourWines.vintnersTitle3"
              contentEditable={this.props.editMode}
              suppressContentEditableWarning
            />
            <div className="scrollAnchor">
              <Map handleRegionClicked={this.handleRegionClicked} />
            </div>
            <RegionsCards
              editMode={this.props.editMode}
              handleRegionClicked={this.handleRegionClicked}
            />
          </div>
        ) : (
            <div className="selectedBox">
              <InfoBox
                selectedRegion={this.state.selectedRegion}
                unselect={this.unselect}
                scrollTo={this.scrollTo}
              />
            </div>
          )}
      </div>
    );
  }
}

export default withRouter(MapComponent);
