import React from "react";
import { CMSController } from "./cmsController";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import ReactHtmlParser from 'react-html-parser';

const CMSLabel = ({ divType = "div", contentEditable, suppressContentEditableWarning, label, defaultLabel, ...props }) => {

  if (divType !== "div") {
    const collectChanges = evt => {
      CMSController.setLabel(label, evt.target.innerHTML);
    };

    return React.createElement(
      divType || "div",
      {
        contentEditable,
        suppressContentEditableWarning,
        onInput: collectChanges,
        ...props
      },
      ReactHtmlParser(CMSController.getLabel(label) || defaultLabel)
    );
  }
  
  if (contentEditable) {
    return <CKEditor
      editor={InlineEditor} data={CMSController.getLabel(label) || defaultLabel} onChange={(event, editor) => {
        const data = editor.getData();
        CMSController.setLabel(label, data);
      }} />

  }
  return React.createElement(
    "div",
    {
      ...props
    },
    ReactHtmlParser(CMSController.getLabel(label) || defaultLabel)
  );
}

export default CMSLabel;
