import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import purpleLogo from "../../ressources/purpleLogo.svg";
import gcBanner from "../../ressources/GC_400x400.jpg";
import SearchBox from "../ourWines/searchBox";
import { CMSController } from "../../cms/cmsController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Flip from "react-reveal/Bounce";
import "./menu.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getStateColors from "./color";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = getStateColors(props.location);
    this.state.mobileMenuOpened = false;
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => getStateColors(nextProps.location));
  }

  toggleMenu = () => {
    this.setState({
      mobileMenuOpened: !this.state.mobileMenuOpened
    });
  };

  notifySave = () => {
    toast.success("Changes have been successfully saved", {
      autoClose: 5000
    });
  };
  notifyError = err => {
    toast.error(`An error has occured: ${err}`, {
      autoClose: 5000
    });
  };

  render() {
    var menuTextColor = {
      color: this.state.menuColor
    };
    let menuClass = "menu";
    if (menuTextColor.color === "white") {
      menuClass += " menuWhite";
    }
    return (
      <div className={menuClass}>
        <style>
          {`
          .menu {
            background: ${this.state.menuBackGround};
          }
          
          .menuHover:hover:after {
            margin-top: 5px;
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            background: ${this.state.menuColor};
          }
         `}
        </style>
        <div>
          {this.props.editMode &&
            <div className="editableBanner">
              <button
                className="saveButton"
                onClick={() => {
                  CMSController.save((err, status) => {
                    if (err) {
                      this.notifyError(err);
                      return;
                    }
                    this.notifySave();
                  });
                }}
              >
                Save modifications
              </button>
            </div>
          }
        </div>
        <SearchBox color={this.state.menuColor} />
        <ToastContainer />
        <Link className="menuHover" to="/">
          <p style={menuTextColor}>Home</p>
        </Link>
        <Link className="menuHover" to="/about-us">
          <p style={menuTextColor}>About</p>
        </Link>
        <img
          id="logoMenu"
          src={this.state.menuLogo}
          alt="logo"
          onClick={this.props.toggleEditable}
        />
        {this.state.mobileMenuOpened ? (
          <FontAwesomeIcon
            icon={faTimes}
            onClick={this.toggleMenu}
            id="menuIcon"
            style={menuTextColor}
          />
        ) : (
            <FontAwesomeIcon
              icon={faBars}
              onClick={this.toggleMenu}
              id="menuIcon"
              style={menuTextColor}
            />
          )}

        <Link className="menuHover" to="/our-wines">
          <p style={menuTextColor}>Wines</p>
        </Link>
        <Link className="menuHover" to="/contact">
          <p style={menuTextColor}>Contact</p>
        </Link>

        <div id="socialIconsMenu">
          <Link className="gcContainer" to="/grand-cru">
            <img id="gcBanner" alt="Grand Cru Banner" src={gcBanner} />
            <h1 id="gcBannerText">Watch the Film<br />Grand Cru</h1>
          </Link>
          <a
            className="socialIconsMenu"
            href="https://www.instagram.com/alianewines/"
            target="Blank"
          >
            <img id="igLogo" alt="Instagram" src={this.state.igLogo} />
          </a>
        </div>

        <div className="mobileMenu">
          {this.state.mobileMenuOpened &&
            <Flip>
              <img
                className="menuMobileLogo"
                src={purpleLogo}
                alt="logo"
                onClick={this.props.toggleEditable}
              />
              <Link className="mobileMenuItem" to="/" onClick={this.toggleMenu}>
                <p>Home</p>
              </Link>
              <Link
                className="mobileMenuItem"
                to="/about-us"
                onClick={this.toggleMenu}
              >
                <p>About</p>
              </Link>
              <Link
                className="mobileMenuItem"
                to="/our-wines"
                onClick={this.toggleMenu}
              >
                <p>Wines</p>
              </Link>
              <Link
                className="mobileMenuItem"
                to="/contact"
                onClick={this.toggleMenu}
              >
                <p>Contact</p>
              </Link>
              <Link
                className="mobileMenuItem"
                to="/grand-cru"
                onClick={this.toggleMenu}
              >
                <p>Grand Cru</p>
              </Link>
              <a
                className="mobileMenuItem"
                href="https://www.instagram.com/alianewines/"
                target="Blank"
                style={{ margin: 20 }}
              >
                <p>Instagram</p>
              </a>
            </Flip>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    location: state.router.location.pathname
  };
};

export default connect(mapStateToProps)(Menu);
