import React from "react";

export default function CMSContainer({ children }) {
    return (
        <div>
            {children}
            <div div style={{ height: 100, display: 'none' }}>
                Edit mode
            </div>
        </div>
    )
}