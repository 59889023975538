import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./footer.css";
import PrintProvider, { NoPrint } from "react-easy-print";
import getStateColors from "./color";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = getStateColors(props.location);
  }
  
  componentWillReceiveProps(nextProps) {
    this.setState(state => getStateColors(nextProps.location));
  }
  render() {
    var footerTextColor = {
      color: this.state.footerColor
    };
    const year = new Date().getFullYear();
    return (
      <div id="footer">
        <PrintProvider>
          <NoPrint name="noPrint">
            <Link to="/all-our-wines">
              <p style={footerTextColor}>Our Wines</p>
            </Link>
            <Link to="/about-us">
              <p style={footerTextColor}>About</p>
            </Link>
            <Link to="/credits">
              <p style={footerTextColor}>Credits</p>
            </Link>
            <Link to="/contact">
              <p style={footerTextColor}>Contact</p>
            </Link>
            <p style={{...footerTextColor, fontSize: 10}}>&copy;2020-{year} alinewines.com</p>
          </NoPrint>
        </PrintProvider>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    location: state.router.location.pathname
  };
};

export default connect(mapStateToProps)(Footer);
