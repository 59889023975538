import React from "react";
import { Link } from "react-router-dom";


export function WineMenu() {
    return (<div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Link style={{ textDecoration: "none" }} to="/our-domains">
                <h2 className="aboutDirectLinkToAllWines">Our Vintners &amp; Domains</h2>
              </Link>
            </div>
            <div className="whiteborderAbout"></div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Link style={{ textDecoration: "none" }} to="/all-our-wines">
                <h2 className="aboutDirectLinkToAllWines">Our Wines</h2>
              </Link>
            </div>
          <div className="whiteborderAbout"></div>
            <Link
              style={{ textDecoration: "none" }}
              to="/find-our-wines-in-california"
            >
              <h2 className="aboutDirectLinkToAllWines">
                Where You Can Find Our Wines in California
                </h2>
            </Link>
          </div>);
}