import React, { Component } from "react";
import "./infoBox.css";
import { withRouter } from "react-router";
import Breadcrumb from "../app/breadcrumb";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { CMSController } from "../../cms/cmsController";
import domaineIcon from "../../ressources/svgIcons/noun_landscape_2069732.svg";
import AddFormComponent from "./formAddComponent";
import ModifyFormComponent from "./formModifyComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/LibraryAdd";

const styles = (theme) => ({
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  modal: {
    overflow: "scroll",
    padding: "50px"
  },
  editIcons: {
    "&:hover": {
      color: "#97506d",
      transform: "scale(1.1, 1.1)"
    },
    cursor: "pointer"
  },
  editIconsContainer: {
    display: "flex",
    justifyContent: "center"
  }
});

class InfoBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRegion: props.match.params.region,
      selectedDomaine: props.match.params.domain,
      selectedWine: "",
      open: false,
      modify: false,
      formData: {},
      domainToModify: {}
    };
  }
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  componentWillReceiveProps(nextProps) {
    this.setState((prevState) => ({
      selectedRegion: nextProps.match.params.region,
      selectedDomaine: nextProps.match.params.domain,
      selectedWine: nextProps.match.params.domain,
      open: false
    }));
  }
  displayDomaineList = (selectedRegion, selectedDomaine) => {
    this.props.history.push(
      "/our-wines/" + selectedRegion + "/" + selectedDomaine
    );
  };
  closeModal = () => {
    this.setState({ open: false, modify: false });
  };
  addNewDomain = () => {
    this.setState({ open: true, formData: {} });
  };

  submitNewDomain = (evt) => {
    let imgInBase64 = evt.formData.imgSrc;
    let carouselImgs = evt.formData.carouselImgs;
    CMSController.saveBase64Image(() => { }, imgInBase64).then((response) => {
      let newDomaine = {
        ...evt.formData,
        RegionName: this.state.selectedRegion,
        imgSrc: response
      };
      if (carouselImgs !== undefined) {
        CMSController.saveBase64ImagesSet(carouselImgs).then((response) => {
          newDomaine.carouselImgs = response;
        });
      }
      CMSController.addCollectionEntry("domainesSpecifications", newDomaine);
      this.forceUpdate();
    });
    this.closeModal();
  };
  deleteDomain = domain => {
    CMSController.deleteCollectionEntry("domainesSpecifications", domain);
    this.forceUpdate();
  };
  modifyDomain = (domain) => {
    let formData = {
      DomaineName: domain.DomaineName,
      DomainePlace: domain.DomainePlace,
      DomaineDescription: domain.DomaineDescription
    };
    this.setState({
      modify: true,
      formData: formData,
      domainToModify: domain
    });
  };
  submitDomainModification = (evt, domainToModify) => {
    let modifiedDomain = {};
    let imgInBase64 = evt.formData.imgSrc;
    let carouselImgs = evt.formData.carouselImgs;
    if (imgInBase64 === undefined && carouselImgs === undefined) {
      modifiedDomain = {
        ...evt.formData,
        RegionName: this.state.selectedRegion,
        imgSrc: domainToModify.imgSrc,
        carouselImgs: domainToModify.carouselImgs
      }
      if (modifiedDomain.DomaineName !== this.state.domainToModify.DomaineName) {
        // Need to update all wines aswell
        CMSController.labels["winesSpecifications"].forEach(w => {
          if (w.Domaine === this.state.domainToModify.DomaineName && w.Cepage === this.state.domainToModify.RegionName) {
            w.Domaine = modifiedDomain.DomaineName;
          }
        })
      }
      CMSController.modifyCollectionEntry(
        "domainesSpecifications",
        domainToModify,
        modifiedDomain
      );
    } else {
      CMSController.saveBase64Image(() => { }, imgInBase64).then((response) => {
        modifiedDomain = {
          ...evt.formData,
          RegionName: this.state.selectedRegion,
          imgSrc: response,
          carouselImgs: domainToModify.carouselImgs
        };
        if (carouselImgs !== undefined) {
          CMSController.saveBase64ImagesSet(carouselImgs).then((response) => {
            modifiedDomain.carouselImgs = response;
          });
        }
        CMSController.modifyCollectionEntry(
          "domainesSpecifications",
          domainToModify,
          modifiedDomain
        );
        this.forceUpdate();
      });
    }
    this.closeModal();
  };

  render() {
    let { selectedRegion } = this.state;
    const { classes, editMode } = this.props;
    const regionSpec = CMSController.labels.regionsSpecifications
      .filter(regionSpec => regionSpec.RegionName === selectedRegion).pop();
    const domainesList = CMSController.labels.domainesSpecifications
      .filter(domaines => domaines.RegionName === selectedRegion)
      .map(
        (domain, i) => {
          return (
            <div key={i}>
              <div
                className="domaineVignette"
                onClick={this.displayDomaineList.bind(
                  this,
                  selectedRegion,
                  domain.DomaineName
                )}
              >
                <p className="domaineName">{domain.DomaineName}</p>
                <img
                  className="domaineImg"
                  src={domain.imgSrc}
                  alt={domain.DomaineName}
                />
              </div>
              {editMode &&
                <div className={classes.editIconsContainer}>
                  <EditIcon
                    className={classes.editIcons}
                    onClick={() => this.modifyDomain(domain)}
                    fontSize="large"
                  />
                  <DeleteIcon
                    className={classes.editIcons}
                    onClick={() => this.deleteDomain(domain)}
                    fontSize="large"
                  />
                </div>
              }
            </div>
          );
        }
      );
    return (
      <div>
        <Breadcrumb />
        <div id="selectedVinters">
          <div className="card">
            <h1>{selectedRegion}</h1>
            <img
                className="regionImg"
                src={regionSpec.src}
                alt={regionSpec.RegionName}
              />
            <div className="regionSpec">
              <p
                contentEditable={editMode}
                suppressContentEditableWarning
              >
                {regionSpec.RegionDescription}
              </p>
            </div>
          </div>
          <div className="regionWinesList">
            {domainesList}
            {editMode &&
              <div>
                <div
                  className="domaineVignette"
                  onClick={() => this.addNewDomain()}
                >
                  <img
                    src={domaineIcon}
                    alt="Wine by iconoci from the Noun Project"
                    className="domaineImg"
                  />
                  <AddIcon
                    className={classes.editIcons}
                    fontSize="large"
                    onClick={() => this.addNewDomain()}
                  />
                </div>
              </div>
            }
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <Paper className={classes.root}>
            <AddFormComponent
              formTitle="Adding a new Domain"
              formSchema="addingDomain"
              onSubmit={(evt) => this.submitNewDomain(evt)}
            />
          </Paper>
        </Modal>
        <Modal
          open={this.state.modify}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <Paper className={classes.root}>
            <ModifyFormComponent
              formTitle="Modify Domain"
              formSchema="modifyDomain"
              defaultValue={this.state.formData}
              onSubmit={(evt) =>
                this.submitDomainModification(evt, this.state.domainToModify)
              }
            />
          </Paper>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(InfoBox));
