import React, { Component } from "react";
import "./searchBox.css";
import { withRouter } from "react-router";
import Lunr from "lunr";
import wineBottleIcon from "../../ressources/svgIcons/noun_Wine_9305.svg";
import regionIcon from "../../ressources/svgIcons/noun_vineyard_156400.svg";
import domaineIcon from "../../ressources/svgIcons/noun_landscape_2069732.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { CMSController } from "../../cms/cmsController";

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      winesSearchResults: [],
      regionsSearchResults: [],
      domainesSearchResults: [],
      selectedRegion: props.match.params.region,
      selectedDomaine: props.match.params.domain,
      toggleResultsList: false
    };
  }
  handleChange = e => {
    const winesSpecifications = CMSController.labels.winesSpecifications;
    const regionsSpecifications = CMSController.labels.regionsSpecifications;
    const domainesSpecifications = CMSController.labels.domainesSpecifications;
    const winesIdx = Lunr(function() {
      this.field("Cepage");
      this.field("Domaine");
      this.field("Nom");
      this.field("Appellation");
      winesSpecifications.forEach(function(winesSpecification, i) {
        winesSpecification.id = i;
        this.add(winesSpecification);
      }, this);
    });

    const domainesIdx = Lunr(function() {
      this.field("RegionName");
      domainesSpecifications.forEach(function(domainesSpecification, i) {
        domainesSpecification.id = i;
        this.add(domainesSpecification);
      }, this);
    });

    const regionsIdx = Lunr(function() {
      this.field("RegionName");
      regionsSpecifications.forEach(function(regionsSpecification, i) {
        regionsSpecification.id = i;
        this.add(regionsSpecification);
      }, this);
    });
    let input = e.target.value.toLowerCase();
    let winesFilteredList = [];
    if (input !== "") {
      let winesSearchResult = winesIdx.search(input + "*");
      if (winesSearchResult.length === 0) {
        winesSearchResult = winesIdx.search(input);
      }
      winesSearchResult.forEach(function(element) {
        winesFilteredList = [
          ...winesFilteredList,
          winesSpecifications[element.ref]
        ];
      });
    }
    let domainesFilteredList = [];
    if (input !== "") {
      let domainesSearchResult = domainesIdx.search(input + "*");
      if (domainesSearchResult.length === 0) {
        domainesSearchResult = domainesIdx.search(input);
      }
      domainesSearchResult.forEach(function(element) {
        domainesFilteredList = [
          ...domainesFilteredList,
          domainesSpecifications[element.ref]
        ];
      });
    }
    let regionsFilteredList = [];
    if (input !== "") {
      let regionSearchResult = regionsIdx.search(input + "*");
      if (regionSearchResult.length === 0) {
        regionSearchResult = regionsIdx.search(input);
      }
      regionSearchResult.forEach(function(element) {
        regionsFilteredList = [
          ...regionsFilteredList,
          regionsSpecifications[element.ref]
        ];
      });
    }

    this.setState(prevState => ({
      winesSearchResults: winesFilteredList,
      regionsSearchResults: regionsFilteredList,
      domainesSearchResults: domainesFilteredList
    }));
  };

  navigateToSearch = (e, map) => {
    if (map === "wines") {
      let selection = this.state.winesSearchResults[e];
      this.props.history.push(
        "/our-wines/" +
          selection.Cepage +
          "/" +
          selection.Domaine +
          "/" +
          selection.Nom
      );
    } else if (map === "domaines") {
      let selection = this.state.domainesSearchResults[e];
      this.props.history.push(
        "/our-wines/" + selection.RegionName + "/" + selection.DomaineName
      );
    } else {
      let selection = this.state.regionsSearchResults[e];
      this.props.history.push("/our-wines/" + selection.RegionName);
    }
    this.setState({
      toggleResultsList: false
    });
  };

  render() {
    const {
      winesSearchResults,
      domainesSearchResults,
      regionsSearchResults
    } = this.state;

    var winesSearching = winesSearchResults.map((item, i) => {
      return item && item.Domaine && i < 5 ? (
        <li
          key={i}
          className="searchHover"
          onClick={() => this.navigateToSearch(i, "wines")}
        >
          <img
            src={wineBottleIcon}
            alt="Wine by iconoci from the Noun Project"
            className="searchResultIcons"
          />
          {item.Domaine}, {item.Nom}
        </li>
      ) : null;
    });
    var domainesSearching = domainesSearchResults.map((item, i) => {
      return item && item.DomaineName && i < 5 ? (
        <li
          key={i}
          className="searchHover"
          onClick={() => this.navigateToSearch(i, "domaines")}
        >
          <img
            src={domaineIcon}
            alt="Wine by iconoci from the Noun Project"
            className="searchResultIcons"
          />
          {item.DomaineName}, {item.DomainePlace}
        </li>
      ) : null;
    });
    var regionsSearching = regionsSearchResults.map((item, i) => {
      return item && item.RegionName ? (
        <li
          className="searchHover"
          key={i}
          onClick={() => this.navigateToSearch(i, "regions")}
        >
          <img
            src={regionIcon}
            alt="Wine by iconoci from the Noun Project"
            className="searchResultIcons"
          />
          {item.RegionName}
        </li>
      ) : null;
    });

    return (
      <div className="searchBox">
        <style>
          {`
            .searchBox div input {
              color: ${this.props.color};
            }
            .SearchInputIcon{
              color: ${this.props.color};
              margin-right: 5px;
            }
            .inputBox{
              border: 1px solid ${this.props.color};
              border-radius: 5px;
              padding: 10px;
            }
         `}
        </style>
        <div className="inputBox">
          <FontAwesomeIcon icon={faSearch} className="SearchInputIcon" />
          <input
            type="text"
            className="input"
            placeholder="Search..."
            onChange={this.handleChange}
            onFocus={() => {
              this.setState({
                toggleResultsList: true
              });
            }}
          />
        </div>
        {this.state.toggleResultsList ? (
          <ul className="searchListResults">
            {regionsSearching}
            {domainesSearching}
            {winesSearching}
          </ul>
        ) : null}
      </div>
    );
  }
}

export default withRouter(SearchBox);
