import React, { Component } from "react";
import Form from "react-jsonschema-form";
import "bootstrap/dist/css/bootstrap.css";
import uiSchema from "./formModifyComponent";

class AddFormComponent extends Component {
  render() {
    let { formTitle, onSubmit, formSchema } = this.props;
    const schema = {
      addingDomain: {
        title: formTitle,
        description: "Please fill in the form to add a new Domain to the database",
        type: "object",
        required: ["DomaineName", "DomainePlace", "DomaineDescription", "imgSrc"],
        properties: {
          DomaineName: {
            type: "string",
            title: "Domaine Name"
          },
          DomainePlace: {
            type: "string",
            title: "Domaine Place"
          },
          DomaineDescription: {
            type: "string",
            title: "Domaine Description"
          },
          imgSrc: {
            type: "string",
            format: "data-url",
            title: "Image principale"
          },
          carouselImgs: {
            type: "array",
            title: "Domaines images",
            items: {
              type: "string",
              format: "data-url"
            }
          }
        }
      },
      addingHomeImgs: {
        title: formTitle,
        description: "Please load new set of Wallpaper home images to the database",
        type: "object",
        required: ["wallpaperImgs"],
        properties: {
          wallpaperImgs: {
            type: "array",
            title: "Home wallpaper images",
            items: {
              type: "string",
              format: "data-url"
            }
          }
        }
      },
      addingProduct: {
        title: formTitle,
        description: "Please fill in the form to add a new Product to the database",
        type: "object",
        required: [
          "Nom",
          "Appellation",
          "Terroirs",
          "Farming",
          "GrapeVarietals",
          "VineAge",
          "AverageYield",
          "WineMaking",
          "TastingNotes",
          "AnnualProduction"
        ],
        properties: {
          Nom: {
            type: "string",
            title: "Nom"
          },
          About: {
            type: "string",
            title: "About"
          },
          Appellation: {
            type: "string",
            title: "Appellation"
          },
          Terroirs: {
            type: "string",
            title: "Terroirs"
          },
          Farming: {
            type: "string",
            title: "Farming"
          },
          GrapeVarietals: {
            type: "string",
            title: "Grape Varietals"
          },
          VineAge: {
            type: "string",
            title: "Vine Age"
          },
          AverageYield: {
            type: "string",
            title: "Average Yield"
          },
          WineMaking: {
            type: "string",
            title: "WineMaking"
          },
          TastingNotes: {
            type: "string",
            title: "Tasting Notes"
          },
          AnnualProduction: {
            type: "string",
            title: "Annual Production"
          },
          imgSrc: {
            type: "string",
            format: "data-url",
            title: "Étiquette"
          }
        }
      },
      addingContact: {
        title: formTitle,
        description: "Please fill in the form to add a new Contact to the database",
        type: "object",
        required: ["name", "title", "phoneNumber", "email", "location", "area"],
        properties: {
          name: {
            type: "string",
            title: "Contact Name"
          },
          title: {
            type: "string",
            title: "Contact Title"
          },
          phoneNumber: {
            type: "string",
            title: "Phone Number"
          },
          email: {
            type: "string",
            title: "Contact Email"
          },
          location: {
            type: "string",
            title: "Contact Location",
            enum: ["Northern California", "Orange County", "San Diego", "Los Angeles", "Palm Desert"]
          },
          area: {
            type: "string",
            title: "Contact Area"
          }
        }
      },
      addingPlace: {
        title: formTitle,
        description: "Please fill in the form to add new Place in the database",
        type: "object",
        required: ["name", "website"],
        properties: {
          name: {
            type: "string",
            title: "Name"
          },
          website: {
            type: "string",
            title: "Website"
          },
          address: {
            type: "string",
            title: "Address"
          },
          location: {
            type: "object",
            title: "GPS",
            properties: {
              lat: {
                type: "number",
                title: "Latitude"
              },
              lng: {
                type: "number",
                title: "Longitude"
              }
            }
          }
        }
      },
      addingCredit: {
        title: formTitle,
        description: "Please fill in the form to add new Credit in the database",
        type: "object",
        required: ["name", "websiteUrl", "logoSrc"],
        properties: {
          name: {
            type: "string",
            title: "Name"
          },
          websiteUrl: {
            type: "string",
            title: "Website"
          },
          logoSrc: {
            type: "string",
            format: "data-url",
            title: "Logo"
          }
        }
      }
    };

    return <Form schema={schema[formSchema]} uiSchema={uiSchema} onSubmit={onSubmit} />;
  }
}
export default AddFormComponent;
