import whiteLogo from "../../ressources/whiteLogo.svg";
import purpleLogo from "../../ressources/purpleLogo.svg";
import whiteIgLogo from "../../ressources/instagram_logo.svg";
import purpleIgLogo from "../../ressources/instagram_logo_purple.svg";


export default function getStateColors(location) {
    if (
      location.startsWith("/our-wines") ||
      ["/contact", "/grand-cru", "/all-our-wines", "/credits", "/find-our-wines-in-california", "/our-domains"].indexOf(location) >= 0
    ) {
      return {
        footerColor: "#BC204B",
        menuColor: "#BC204B",
        menuLogo: purpleLogo,
        igLogo: purpleIgLogo,
        menuBackGround: "white"
      };
    } else if (location === "/about-us") {
        return {
            footerColor: "white",
            menuColor: "#BC204B",
            menuLogo: purpleLogo,
            igLogo: purpleIgLogo,
            menuBackGround: "white"
          };
    } else if (location === "/credits") {
        return {
            footerColor: "white",
            menuColor: "white",
            menuLogo: whiteLogo,
            igLogo: whiteIgLogo,
            menuBackGround: "black"
          };
    } else {
      return {
        footerColor: "white",
        menuColor: "white",
        menuLogo: whiteLogo,
        igLogo: whiteIgLogo,
        menuBackGround: "rgba(0,0,0,0)"
      };
    }
  }