import React, { Component } from "react";
import VideoBackground from "./videoBackground";
import { withStyles } from "@material-ui/core/styles";
import "./index.css";
import Wallpaper from "../ourWines/wallpaper";
import { CMSController } from "../../cms/cmsController";
import AddFormComponent from "../ourWines/formAddComponent";
import Modal from "@material-ui/core/Modal";
import EditIcon from "@material-ui/icons/Edit";
import Paper from "@material-ui/core/Paper";
import CMSQuote from "../../cms/cmsQuote";
import whiteLogo from "../../ressources/whiteLogo.svg";

const styles = theme => ({
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  modal: {
    overflow: "scroll",
    padding: "50px"
  },
  wallPaperContainer: {
    paddingTop: "50px",
    paddingBottom: "50px",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(250, 250, 250, 0.8)"
  }
})

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      formData: {}
    };
  }

  editWallpaperImgs() {
    this.setState({
      open: true,
      formData: {}
    })
  }

  closeModal = () => {
    this.setState({ open: false });
  };

  submitNewHomeImgsWallpaper = (evt) => {
    CMSController.saveBase64ImagesSet(evt.formData.wallpaperImgs).then((response) => {
      CMSController.addCollectionEntry("homeWallpaperImgs", response);
    })
    this.forceUpdate();
    this.closeModal();
  }

  render() {
    const { classes } = this.props;
    let wallpaperImgs = [];
    let wallpaperImgs2 = [];
    for (let i = 1; i < 18; i++) {
      // Skip 4
      if (i === 4) continue;
      wallpaperImgs.push({ original: `/images/home/wallpaper/${i}.jpg`, small: `/images/home/wallpaper/${i}_sm.jpg`, high: `/images/home/wallpaper/${i}_high.jpg` })
    }
    for (let i = 18; i < 22; i++) {
      wallpaperImgs2.push({ original: `/images/home/wallpaper/${i}.jpg`, small: `/images/home/wallpaper/${i}_sm.jpg`, high: `/images/home/wallpaper/${i}_high.jpg` })
    }
    return (
      <div id="homeSection">
        <div id="homePage">
          <VideoBackground />
          <img
            id="mobileLogo"
            src={whiteLogo}
            alt="logo"
          />
          <div id="slogan">
            <h1 className="audreyFontUppercase">High Craft Authentic French Wines</h1>
            <h1 className="audreyFont">sourced by a native Burgundian<div className="asterisk" style={{ color: "#ffffff", fontWeight: "bold", fontSize: 28 }}>*</div></h1>
          </div>
        </div>
        <CMSQuote id="home.burgundy" title="<div class='asterisk'>*</div> What does it mean to be Burgundian ?" content="There is a notion of service, a drive for perfection and precision, a sense of place, time and scale. You work your “plot” tirelessly, you trust your intuition to extract its best, and listen to something bigger than yourself.<br />Fully alive to your senses, you are now prepared to share, experience, and celebrate moments of community." />
        {
          this.props.editMode &&
          <div style={{ backgroundColor: "white", border: "1px solid #000" }}><EditIcon
            onClick={() => this.editWallpaperImgs()}
            fontSize="large"
          /></div>
        }
        <div>
          <div style={{ backgroundColor: "rgba(255,255,255,0.8)", justifyContent: "center", padding: 20 }}>
            <Wallpaper gallery={wallpaperImgs} />
            <h1 style={{ textAlign: 'center', color: '#BC204B' }}>Vibrant - Sensorial - Communal</h1>
            <Wallpaper gallery={wallpaperImgs2} />
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <Paper className={classes.root}>
            <AddFormComponent
              formTitle="Adding new set of home images for Wallpaper"
              formSchema="addingHomeImgs"
              onSubmit={evt => this.submitNewHomeImgsWallpaper(evt)}
            />
          </Paper>
        </Modal>
      </div >
    );
  }
}

export default withStyles(styles)(Home);
