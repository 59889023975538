import React, { Component } from "react";
import MapComponent from "./mapComponent";
import "./index.css";
import purpleLogo from "../../ressources/purpleLogo.svg";


class OurWines extends Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  render() {
    let { history, editMode } = this.props;
    return (
      <div className="ourWines">
        <img
          id="mobileLogo"
          src={purpleLogo}
          alt="logo"
        />
        <MapComponent
          editMode={editMode}
          className="franceWinesMap"
          history={history}
        />
      </div>
    );
  }
}

export default OurWines;
