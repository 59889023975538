import React, { Component } from "react";
import "./infoDomaineBox.css";
import { withRouter } from "react-router";
import Breadcrumb from "../app/breadcrumb";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.css";
import { CMSController } from "../../cms/cmsController";
import AddFormComponent from "./formAddComponent";
import ModifyFormComponent from "./formModifyComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/LibraryAdd";
import wineBottleIcon from "../../ressources/svgIcons/noun_Wine_9305.svg";
import Wallpaper from "./wallpaper";
import { WineCard } from "./winesCards";

const styles = theme => ({
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  modal: {
    overflow: "scroll",
    padding: "50px"
  },
  editIcons: {
    "&:hover": {
      color: "#97506d",
      transform: "scale(1.1, 1.1)"
    },
    cursor: "pointer"
  },
  editIconsContainer: {
    display: "flex",
    justifyContent: "center"
  },
  carouselContainer: {
    maxWidth: "600px"
  }
});

class InfoRegionBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRegion: props.match.params.region,
      selectedDomaine: props.match.params.domain,
      selectedWine: props.match.params.wine,
      open: false,
      modify: false,
      formData: {},
      wineToModify: {}
    };
  }
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  componentWillReceiveProps(nextProps) {
    this.setState(prevState => ({
      selectedRegion: nextProps.match.params.region,
      selectedDomaine: nextProps.match.params.domain,
      selectedWine: nextProps.match.params.wine,
      open: false
    }));
  }
  displayWineSpec = (selectedRegion, selectedDomaine, selectedWine) => {
    this.props.history.push(
      "/our-wines/" +
      selectedRegion +
      "/" +
      selectedDomaine +
      "/" +
      selectedWine
    );
  };
  closeModal = () => {
    this.setState({ open: false, modify: false });
  };
  addNewProduct = () => {
    this.setState({ open: true, formData: {} });
  };
  submitNewProduct = evt => {
    let imgInBase64 = evt.formData.imgSrc;
    CMSController.saveBase64Image(() => { }, imgInBase64).then(response => {
      let newProduct = {
        ...evt.formData,
        Cepage: this.state.selectedRegion,
        Domaine: this.state.selectedDomaine,
        imgSrc: response
      };
      CMSController.addCollectionEntry("winesSpecifications", newProduct);
      this.forceUpdate();
    });
  };
  deleteProduct = domaineProd => {
    CMSController.deleteCollectionEntry("winesSpecifications", domaineProd);
    this.forceUpdate();
  };
  modifyProduct = domaineProd => {
    let formData = {
      Nom: domaineProd.Nom,
      Appellation: domaineProd.Appellation,
      Terroirs: domaineProd.Terroirs,
      Farming: domaineProd.Farming,
      GrapeVarietals: domaineProd.GrapeVarietals,
      VineAge: domaineProd.VineAge,
      AverageYield: domaineProd.AverageYield,
      WineMaking: domaineProd.WineMaking,
      TastingNotes: domaineProd.TastingNotes,
      AnnualProduction: domaineProd.AnnualProduction
    };
    this.setState({
      modify: true,
      formData: formData,
      wineToModify: domaineProd
    });
  };
  submitProductModification = (evt, wineToModify) => {
    let modifiedProduct = {};
    let imgInBase64 = evt.formData.imgSrc;
    if (imgInBase64 === undefined) {
      modifiedProduct = {
        ...evt.formData,
        Cepage: this.state.selectedRegion,
        Domaine: this.state.selectedDomaine,
        imgSrc: wineToModify.imgSrc
      }
      CMSController.modifyCollectionEntry(
        "winesSpecifications",
        wineToModify.index,
        modifiedProduct
      );
    } else {
      CMSController.saveBase64Image(() => { }, imgInBase64).then(response => {
        modifiedProduct = {
          ...evt.formData,
          Cepage: this.state.selectedRegion,
          Domaine: this.state.selectedDomaine,
          imgSrc: response
        };
        CMSController.modifyCollectionEntry(
          "winesSpecifications",
          wineToModify.index,
          modifiedProduct
        );
        this.forceUpdate();
      });
    }
    this.closeModal();
  };

  render() {
    let { selectedRegion, selectedDomaine } = this.state;
    const { classes, editMode } = this.props;
    const domainesSpec = CMSController.labels.domainesSpecifications
      .filter(domaineSpec => domaineSpec.DomaineName === selectedDomaine && domaineSpec.RegionName === selectedRegion)
      .map(
        (domaineSpec, i) => {
          let galleryPhotos = [];
          if (domaineSpec.carouselImgs && domaineSpec.carouselImgs !== []) {
            domaineSpec.carouselImgs.forEach(photo => {

              galleryPhotos.push(
                {
                  original: photo,
                  small: photo.replace(/\.jpeg$/, "_sm.jpeg")
                });
            })
          }
          return (
            <div key={i} className="domaineSpec">
              <div className="DomaineHeader">
                <div className={classes.carouselContainer}>
                  <p className="domaineTitle">{domaineSpec.DomaineName}</p>
                  <p className="domainePlace">
                    {domaineSpec.RegionName}, {domaineSpec.DomainePlace}
                  </p>


                </div>
                {galleryPhotos.length !== 0 ?
                  <Wallpaper gallery={galleryPhotos} />
                  :
                  <img
                    className="domaineImg"
                    src={domaineSpec.imgSrc}
                    alt={domaineSpec.DomaineName}
                  />
                }
              </div>
              <p className="domaineDescription" dangerouslySetInnerHTML={{__html: domaineSpec.DomaineDescription}}>
                
              </p>
            </div >
          );
        }
      );
    const domainesProd = CMSController.labels.winesSpecifications
      .map((item, id) => ({...item, index:id}))
      .filter(winesSpecification => winesSpecification.Cepage === selectedRegion && winesSpecification.Domaine === selectedDomaine)
      .sort((winesSpecifications1, winesSpecifications2) => {
        return winesSpecifications1.Nom
          .localeCompare(winesSpecifications2.Nom)
      })
      .map(
        (domaineProd, i) => {
          return (
            <div key={i} className="domaineProduction">
              <WineCard wine={domaineProd} history={this.props.history} />
              {editMode &&
                <div className={classes.editIconsContainer}>
                  <EditIcon
                    className={classes.editIcons}
                    onClick={() => this.modifyProduct(domaineProd)}
                    fontSize="large"
                  />
                  <DeleteIcon
                    className={classes.editIcons}
                    onClick={() => this.deleteProduct(domaineProd.index)}
                    fontSize="large"
                  />
                </div>
              }
            </div>
          );
        }
      );
    return (
      <div className="domaineInfoContainer" >
        <Breadcrumb />
        <div className="domaines">
          {domainesSpec}
          <div className="domainesProdContainer">
            {domainesProd}
            {editMode &&
              <div className="domaineProduction">
                <div
                  className="productionVignette"
                  onClick={() => this.addNewProduct()}
                >
                  <img
                    src={wineBottleIcon}
                    alt="Wine by iconoci from the Noun Project"
                    className="wineVignette"
                  />
                  <AddIcon
                    className={classes.editIcons}
                    fontSize="large"
                    onClick={() => this.addNewProduct()}
                  />
                </div>
              </div>
            }
          </div>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <Paper className={classes.root}>
            <AddFormComponent
              formTitle="Adding a new product"
              formSchema="addingProduct"
              onSubmit={evt => this.submitNewProduct(evt)}
            />
          </Paper>
        </Modal>
        <Modal
          open={this.state.modify}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <Paper className={classes.root}>
            <ModifyFormComponent
              formTitle="Modify Product"
              formSchema="modifyProduct"
              defaultValue={this.state.formData}
              onSubmit={evt =>
                this.submitProductModification(evt, this.state.wineToModify)
              }
            />
          </Paper>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(InfoRegionBox));
