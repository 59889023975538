import React, { Component } from "react";
import "./regionsCards.css";
import { CMSController } from "../../cms/cmsController";

class RegionsCards extends Component {
  render() {
    const regionsImg = CMSController.labels.regionsSpecifications.filter(
      regionsSpecification => regionsSpecification.desactivated !== true).map(
      (regionImg, i) => {
        return (
          <div
            key={i}
            className="regionListContainer"
            onClick={this.props.handleRegionClicked.bind(
              this,
              regionImg.RegionName
            )}
          >
            <img
              className="regionImg"
              src={regionImg.src}
              alt="region images"
            />
            <div className="overlay">
              <p className="regionName">{regionImg.RegionName}</p>
            </div>
          </div>
        );
      }
    );
    return <div className="flexHorizontal">{regionsImg}</div>;
  }
}

export default RegionsCards;
