import React, { Component } from "react";
// import { Link } from "react-router-dom";
import "./credits.css";
import { CMSController } from "../../cms/cmsController";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/LibraryAdd";
import EditIcon from "@material-ui/icons/Edit";
import AddFormComponent from "../ourWines/formAddComponent";
import ModifyFormComponent from "../ourWines/formModifyComponent";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const styles = (theme) => ({
  modal: {
    overflow: "scroll",
    padding: "50px"
  },
  editIcons: {
    color: "white",
    "&:hover": {
      color: "#97506d",
      transform: "scale(1.1, 1.1)"
    },
    cursor: "pointer"
  },
  editIconsContainer: {
    display: "flex",
    justifyContent: "center"
  }
});

class Credits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modify: false,
      formData: {},
      creditToModify: {}

    }
  }
  addNewCredit = () => {
    this.setState({ open: true, formData: {} });
  };
  closeModal = () => {
    this.setState({ open: false, modify: false });
  };
  submitNewCredit = (evt, idx) => {
    let imgInBase64 = evt.formData.logoSrc;
    CMSController.saveBase64Image(() => { }, imgInBase64).then(response => {
      let newCredit = {
        ...evt.formData,
        logoSrc: response
      };
      CMSController.addCollectionEntry("credits", newCredit);
      this.forceUpdate();
    });

    this.closeModal();
  }
  deleteCredit = credit => {
    CMSController.deleteCollectionEntry("credits", credit);
    this.forceUpdate();
  }
  modifyCredit = (credit) => {

    let formData = {
      name: credit.name,
      websiteUrl: credit.websiteUrl
    }
    this.setState({
      modify: true,
      formData: formData,
      creditToModify: credit
    })
  }
  submitCreditModification = (evt, creditToModify) => {
    let modifiedCredit = {};
    let imgInBase64 = evt.formData.logoSrc;
    if (imgInBase64 === undefined) {
      // if no new image is sent should keep old one
      modifiedCredit = {
        ...evt.formData,
        logoSrc: creditToModify.logoSrc
      }
      CMSController.modifyCollectionEntry(
        "credits",
        creditToModify,
        modifiedCredit
      );
    } else {
      CMSController.saveBase64Image(() => { }, imgInBase64).then(response => {
        modifiedCredit = {
          ...evt.formData,
          logoSrc: response
        }
        CMSController.modifyCollectionEntry(
          "credits",
          creditToModify,
          modifiedCredit
        );
        this.forceUpdate();
      });
    }
    this.closeModal();
  }

  render() {
    const credits = CMSController.labels.credits;
    const { editMode, classes } = this.props;
    return (
      <div id="credits">
        {credits.map((credit, i) => {
          return (
            <div key={i}>
              <a
                href={credit.websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="creditsLogo"
                  src={credit.logoSrc}
                  alt={`${credit.name} logo`}
                />
              </a>
              {editMode &&
                <div className={classes.editIconsContainer}>
                  <EditIcon
                    className={classes.editIcons}
                    onClick={() => this.modifyCredit(credit)}
                    fontSize="large"
                  />
                  <DeleteIcon
                    className={classes.editIcons}
                    onClick={() => this.deleteCredit(credit)}
                    fontSize="large" />
                </div>
              }
            </div>
          )
        })}
        {editMode &&
          <div>
            <AddIcon
              fontSize="large"
              onClick={() => this.addNewCredit()}
            />
          </div>
        }
        <Modal
          open={this.state.open}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <Paper className={classes.root}>
            <AddFormComponent
              formTitle="Adding a new credit"
              formSchema="addingCredit"
              onSubmit={(evt) => this.submitNewCredit(evt)}
            />
          </Paper>
        </Modal>
        <Modal
          open={this.state.modify}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <Paper className={classes.root}>
            <ModifyFormComponent
              formTitle="Modify credit"
              formSchema="modifyCredit"
              defaultValue={this.state.formData}
              onSubmit={evt =>
                this.submitCreditModification(evt, this.state.creditToModify)
              }
            />
          </Paper>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(Credits);
