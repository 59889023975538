import React, { Component } from "react";
import "./winesCards.css";
import wineVignetteImg from "../../ressources/wines/beaujolais/Aliane_Beaujolais-Villages_VV_Specs.jpg";
import { CMSController } from "../../cms/cmsController";
import purpleLogo from "../../ressources/purpleLogo.svg";
import LazyLoad from 'react-lazy-load';

export function WineCard({wine, history}) {
  return (<div
              onClick={() => {
                history.push(
                  "/our-wines/" + wine.Cepage + "/" + wine.Domaine + "/" + wine.Nom
                )
              }}
              className="winesCardsVignette"
            >

              {wine.imgSrc ? (
                <LazyLoad height={250} width={300} offsetVertical={50}>
                  <img
                    src={wine.imgSrc}
                    alt="Wine vignette"
                    className="allOurWineVignette"
                  />
                </LazyLoad>
              ) : (
                  <img
                    src={wineVignetteImg}
                    alt="Wine vignette"
                    className="allOurWineVignette"
                  />
                )}
              <div style={{fontWeight: "bold"}}>
                <p>{wine.Domaine}</p>
                <p>{wine.Nom}</p>
              </div>
            </div>);
}
class WinesCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWine: "",
    };
  }
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  winesListByRegion(region) {
    const wineListByRegion = CMSController.labels.winesSpecifications
      .filter(winesSpecification => winesSpecification.Cepage === region)
      .sort(
        (winesSpecifications1, winesSpecifications2) => {
          return winesSpecifications1.Nom
            .localeCompare(winesSpecifications2.Nom)
        }
      )
      .sort(
        (winesSpecifications1, winesSpecifications2) => {
          return winesSpecifications1.Domaine
            .localeCompare(winesSpecifications2.Domaine)
        }
      )
      .map(
        (wineSpecifications, i) => <WineCard wine={wineSpecifications} key={i} history={this.props.history} />
      );
    return wineListByRegion;
  }
  render() {
    const winesListsByRegion = CMSController.labels.regionsSpecifications
      .filter(
        regionsSpecification => regionsSpecification.desactivated !== true)
      .map(
        (winesListByRegion, i) => {
          return (
            <div key={i} className="WinesListContainer">
              <h1 className="winesListByRegionTitle" onClick={() => this.props.history.push("/our-wines/" + winesListByRegion.RegionName)}>
                {winesListByRegion.RegionName}
              </h1>
              <div className="winesListByRegion">
                {this.winesListByRegion(winesListByRegion.RegionName)}
              </div>
            </div>
          );
        }
      );
    return <div className="winesCardsContainer"><img id="winesMobileLogo" src={purpleLogo} alt="logo" />
      {winesListsByRegion}</div>;
  }
}

export default WinesCards;
