import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CMSController } from "../../cms/cmsController";
import CMSLabel from "../../cms/cmsLabels";
import purpleLogo from "../../ressources/purpleLogo.svg";

const styles = theme => ({
  container: {
    padding: "120px 20px 40px 20px",
    '@media (max-width:1099px)': {
      padding: "200px 10px 0px 10px"
    },
    '@media (max-width:652px)': {
      padding: "100px 10px 0px 10px"
    },
    '& > div': {
      backgroundColor: "rgba(255,255,255,0.9)", padding: 20
    }
  },
  title: {
    textAlign: "center",
    color: "black",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
  ourDomainsList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    "& h3": {
      margin: "0 20px 20px 20px ",
      fontFamily: "GillSans-Light"
    },
    "& h3:hover": {
      color: "#BC204B",
      cursor: "pointer"
    }
  },
  regionContainer: {
    padding: 20,
    width: 450
  },
  regionTitle: {
    color: "#BC204B",
    padding: "10px 10px 0px 20px",
    border: "1px solid #BC204B",
    marginBottom: "20px",
    textTransform: 'uppercase',
    "&:hover": {
      backgroundColor: "#BC204B",
      color: "white",
      transition: "1s",
      webkitTransition: "1s",
      mozTransition: "1s",
      msTransition: "1s",
      oTransition: "1s",
      cursor: "pointer"
    }
  },

  staticBackground: {
    backgroundImage: "url(/images/domaines/vintners.jpg)",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    '@media (max-width:1182px)': {
      background: "none"
    }
  },
  ourVintnersTextContainer: {
    paddingLeft: "20px",
    paddingRight: "20px",
    "& div": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    },
    "& p": {
      fontSize: "1.3em",
      textAlign: "justify",
      maxWidth: "1000px"
    },
    "& h2": {
      color: "#BC204B",
      fontFamily: "GillSans-Light"
    }
  }
});

class OurDomainsList extends Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  routeToDomainSpec = (region, domaine) => {
    this.props.history.push("/our-wines/" + region + "/" + domaine);
  };
  domainsListByRegion(region) {
    const domaineListByRegion = CMSController.labels.domainesSpecifications
      .filter(domain => domain.RegionName === region)
      .sort((domain1, domain2) => {
        return domain1.DomaineName
          .localeCompare(domain2.DomaineName)
      })
      .map(
        (domain, i) => {
          return (
            <div key={i}>
              <h3
                onClick={() =>
                  this.routeToDomainSpec(domain.RegionName, domain.DomaineName)
                }
              >
                {domain.DomaineName}
              </h3>
            </div>
          );
        }
      );
    return domaineListByRegion;
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={`${classes.container} ${classes.staticBackground}`}>
        <div>
          <img id="mobileLogo" src={purpleLogo} alt="logo" />
          <h1 className={classes.title}>Our Vintners &amp; Domains</h1>
          <div className={classes.ourVintnersTextContainer}>
            <div>
              <CMSLabel
                divType="h2"
                label="labels.ourWines.vintnersCommunityTitle"
                contentEditable={this.props.editMode}
                suppressContentEditableWarning
              />
              <CMSLabel
                label="labels.ourWines.vintnersCommunitySection1"
                contentEditable={this.props.editMode}
                suppressContentEditableWarning
              />
              <CMSLabel
                label="labels.ourWines.vintnersCommunitySection2"
                contentEditable={this.props.editMode}
                suppressContentEditableWarning
              />
            </div>
          </div>
          <div className={classes.ourDomainsList}>
            {CMSController.labels.regionsSpecifications
              .filter(
                regionsSpecification => regionsSpecification.desactivated !== true)
              .map((region, i) => {
                return (
                  <div key={i} className={classes.regionContainer}>
                    <h2 onClick={() => this.props.history.push("/our-wines/" + region.RegionName)} className={classes.regionTitle}>{region.RegionName}</h2>
                    {this.domainsListByRegion(region.RegionName)}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(OurDomainsList);
