import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import purpleLogo from "../../ressources/purpleLogo.svg";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  contactContainer: {
    paddingTop: "100px",
    minHeight: "90vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    "& a": {
      color: "#1D1D1B"
    },
    "& a:hover": {
      textDecoration: "none"
    },
    "& h1": {
      fontSize: "24px"
    },
    "& h4": {
      fontFamily: "GillSans-Light",
      fontSize: "18px"
    },
    '@media (max-width:1182px)': {
      padding: "200px 10px 0px 10px"
    },
    '@media (max-width:653px)': {
      padding: "100px 10px 0px 10px"
    }
  },
  logo: {
    height: "auto",
    width: "200px",
    marginRight: "30px",
    '@media (min-width:653px)': {
      display: 'none'
    }
  },
  name: {
    textTransform: "uppercase"
  },
  title: {
    textTransform: "uppercase",
    color: "#BC204B"
  },
  border: {
    borderLeft: "solid #BC204B",
    borderLeftWidth: "1px"
  },
  margin: {
    marginLeft: "10px"
  },
  arobas: {
    fontFamily: "Lato"
  }
});


const ContactUs = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.contactContainer}>
        <img className={classes.logo} src={purpleLogo} alt="logo" />
        <div>
          <div style={{ padding: 30 }}>
            <h1 className={classes.name}>Aline Thiébaut</h1>
            <h1 className={classes.title}>Owner</h1>
            <div className={classes.border}>
              <a href="mailto:aline@alinewines.com" className="href">
                <h4 className={classes.margin}>aline@alinewines.com</h4>
              </a>
              <a href="tel:+1(858)361-4529" className="href">
                <h4 className={classes.margin}>+1 (858) 361-4529</h4>
              </a>
            </div>
          </div>
          <div style={{ padding: 30 }}>
            <h1 className={classes.name} style={{ color: "#bc204b" }}>Main Office</h1>
            <div className={classes.border}>
              <div style={{paddingLeft: 10, marginBottom: 15}}>
                <h4>1261 S. Highland Avenue</h4>
                <h4>Los Angeles, CA 90019</h4>
              </div>
              <a href="mailto:orders@alinewines.com" className="href">
                <h4 className={classes.margin}>orders@alinewines.com</h4>
              </a>
              <a href="tel:+1(707)498-1926" className="href">
                <h4 className={classes.margin}>+1 (707) 498-1926</h4>
              </a>
            </div>
          </div>
          <Link
            style={{ textDecoration: "none" }}
            to="/find-our-wines-in-california"
          >
            <h2 className="aboutDirectLinkToAllWines">
              Where to Find Our Wines in California
                </h2>
          </Link>
        </div>
      </div>

    </div>
  );
}

export default ContactUs;
